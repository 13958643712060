import React, { useEffect } from 'react'
import { Children } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import jwt_decode from "jwt-decode";

function NotAdmin({children}) {
    const token =useSelector(state=>state.GlobalReducer?.token)
    let authedUser=jwt_decode(`${token}`)
  console.log('authedUserauthedUserauthedUser',authedUser)
    if(authedUser?.isAdmin){
      return children
    }
    return <Navigate to='/dashboard'/>
}

export default NotAdmin