import { CHANGE_LANGUAGUE, CHANGE_TOKEN  } from './GlobalActionsTypes' 


export const changeToken = (token)=>{
    return{
        type:CHANGE_TOKEN,
        token:token
    }
}
export const chengeLang = (code)=>{
    return{
        type:CHANGE_LANGUAGUE,
        lang:code
    }
}