import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import NotAdmin from 'utils/NotAdmin'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  
  const UsersPages = lazy(() => import('../modifiedModules/apps/users/ItemsPage'))
  const AdminUsersPages = lazy(() => import('../modifiedModules/apps/adminUsers/ItemsPage'))
  const ContactUsPages = lazy(() => import('../modifiedModules/apps/contactUs/ItemsPage'))
  const ContactUsReportPages = lazy(() => import('../modifiedModules/apps/contactUsReport/ItemsPage'))
  
  const ProjectCategoryPage = lazy(() => import('../modifiedModules/apps/projectCategory/ItemsPage'))
  const ProjectsPage = lazy(() => import('../modifiedModules/apps/projects/ItemsPage'))
  const GeneralSettingsPage = lazy(() => import('../modifiedModules/apps/generalSettings/GeneralSettingsPage'))
  const CategoriesPage = lazy(() => import('../modifiedModules/apps/categories/ItemsPage'))
  const OurClientsPage = lazy(() => import('../modifiedModules/apps/ourClients/ItemsPage'))
  const TeamMembersPage = lazy(() => import('../modifiedModules/apps/teamMembers/ItemsPage'))
  const BlogsPage = lazy(() => import('../modifiedModules/apps/blogs/ItemsPage'))
  const InterestedClientsPage = lazy(() => import('../modifiedModules/apps/interestedClients/ItemsPage'))
  const ServicesPage = lazy(() => import('../modifiedModules/apps/services/ItemsPage'))
  const FeaturesPage = lazy(() => import('../modifiedModules/apps/features/ItemsPage'))
  const CategoriesFeaturesPage = lazy(() => import('../modifiedModules/apps/categoriesFeatures/ItemsPage'))
  const SliderPage = lazy(() => import('../modifiedModules/apps/slider/BrandsPage'))

  const LoginPage = lazy(() => import('../modifiedModules/LoginPage/LoginPage'))


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path='auth/*' element={<Navigate to='/dashboard' />} /> */}
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        {/* <Route
          path='login/*'
          element={
            <SuspensedView>
              <LoginPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/admin/users/*'
          element={
            <SuspensedView>
              <NotAdmin>
                <AdminUsersPages />
              </NotAdmin>
            </SuspensedView>
          }
        />
        <Route
          path='apps/users/*'
          element={
            <SuspensedView>
              <UsersPages />
            </SuspensedView>
          }
        />
        <Route
          path='apps/contact-us/*'
          element={
            <SuspensedView>
              <ContactUsPages />
            </SuspensedView>
          }
        />
        <Route
          path='apps/contact-us-report/*'
          element={
            <SuspensedView>
              <ContactUsReportPages />
            </SuspensedView>
          }
        />
        <Route
          path='apps/projects/*'
          element={
            <SuspensedView>
              <ProjectsPage/>
            </SuspensedView>
          }
        />
        <Route
          path='apps/categories/*'
          element={
            <SuspensedView>
              <CategoriesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/our-clients/*'
          element={
            <SuspensedView>
              <OurClientsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/team-members/*'
          element={
            <SuspensedView>
              <TeamMembersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/services/*'
          element={
            <SuspensedView>
              <ServicesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/features/*'
          element={
            <SuspensedView>
              <FeaturesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/blogs/*'
          element={
            <SuspensedView>
              <BlogsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/categories-features/*'
          element={
            <SuspensedView>
              <CategoriesFeaturesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/general-settings/*'
          element={
            <SuspensedView>
              <GeneralSettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/slider/*'
          element={
            <SuspensedView>
              <SliderPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/interested-clients/*'
          element={
            <SuspensedView>
              <InterestedClientsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/projects-categories/*'
          element={
            <SuspensedView>
              <ProjectCategoryPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
