import { useState } from 'react'
import { useNavigate} from 'react-router-dom'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { changeToken } from 'app/reduxStore/Global/GlobalActions';
 
const LoginPage = () => {
  const dispatch = useDispatch()
  const navigate =useNavigate()
  const [email,setEmail]=useState('')
  const [password,setPassword]=useState('')
  const [isLoading,setIsLoading]=useState(false)
    function login(){
        setIsLoading(true)
        axiosConfig.post(`/admin/login`,{email,password}).then(res=>{
            setIsLoading(false)
            localStorage.setItem('token', res.data.data)
            dispatch(changeToken(res.data.data))
            navigate('/dashboard')
        }).catch(err=>{
            setIsLoading(false)
            toast.error(err.response.data.message?err.response.data.message:'Something went Wrong')
        })
    }
  return (
    <div className='container h-100 d-flex'>
      <div className="card card-custom my-auto w-100">
        <div className="card-header">
            <h3 className="card-title">Login</h3>
            
        </div>
        <div className="card-body">
          <div className="mb-10">
            <label className="form-label">Email</label>
            <input
              type="text"
              className="form-control"
              placeholder="name@example.com"
              value={email}
              onChange={(e)=>{setEmail(e.target.value)}}
            />
            </div>
            
            <div className="mb-10">
              <label className="form-label">Password</label>
              <input
                type="password"
                className="form-control form-control-white"
                placeholder="Password"
                value={password}
                onChange={(e)=>{setPassword(e.target.value)}}
              />
          </div>
            <div className="mb-10">
              <button
                type="button"
                className="btn btn-light-success"
                placeholder="Password"
                onClick={login}
                disabled={isLoading}
              >
              {
              !isLoading?'Login'
              :<ReactLoading type={"spin"} color={'#ffffff'} height={20} width={20} />
            }
              </button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default LoginPage
