/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'

import jwt_decode from "jwt-decode";
import { useSelector } from 'react-redux';
const SidebarMenuMain = () => {
  const intl = useIntl()
    const token =useSelector((state:any)=>state.GlobalReducer?.token)
    let authedUser:any=jwt_decode(`${token}`)

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Contact Us</span>
        </div>
      </div>
      
      <SidebarMenuItem
        to='/apps/contact-us/all'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Contact Us'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>
        </div>
      </div>
      {
        authedUser?.isAdmin&&
          <SidebarMenuItem
            to='/apps/admin/users/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Admin Users'
            fontIcon='bi-layers'
          />
      }
      <SidebarMenuItem
        to='/apps/services/all'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Services'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/our-clients/all'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Our Clients'
        fontIcon='bi-layers'
      />
      {/* <SidebarMenuItem
        to='/apps/blogs/all'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Blogs'
        fontIcon='bi-layers'
      /> */}
      <SidebarMenuItem
        to='/apps/general-settings/all'
        icon='/media/icons/duotune/general/gen051.svg'
        title='General Settings'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/projects-categories/all'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Project Category'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/projects/all'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Projects'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/categories/all'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Categories'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/interested-clients/all'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Interested Clients'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/team-members/all'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Team Members'
        fontIcon='bi-layers'
      />
        <SidebarMenuItem
        to='/apps/slider/all'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Slider'
        fontIcon='bi-layers'
      />
    </>
  )
}

export {SidebarMenuMain}
